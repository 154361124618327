// Libs
import moment from "moment";

import req from "../utilities/request-utility";
import {remove_commas} from "../utilities/remove_commas";

export const bookingFee = async(staff_type = "", fee = null) => {
  if(fee && ((fee.standard_fee && fee.standard_fee != 0) || (fee.urgent_booking_fee && fee.urgent_booking_fee != 0) || (fee.emergency_booking_fee && fee.emergency_booking_fee != 0))){
    return {standard_fee: fee.standard_fee, urgentBookingFee: fee.urgent_booking_fee, emergencyBookingFee: fee.emergency_booking_fee}
  }
  const { data: data } = await req()(
    `admin/bookings/global-message`
  );
  let standard_fee = 77
  if(staff_type.toLowerCase() != "overtjener / afvikler")
    standard_fee = parseFloat(remove_commas(data.standard_fee));
  let urgentBookingFee = parseFloat(remove_commas(data.urgent_booking_fee));
  let emergencyBookingFee = parseFloat(remove_commas(data.emergency_booking_fee));
  return {standard_fee, urgentBookingFee, emergencyBookingFee};
}
// Update the total price by date
export const calculateFee = async(date, state) => {
  state.date = date;
  state.fee = await diffFee(diffDateCalculator(state));
  return state;
};

// Calculates the diff depending on how close to the date to booking is set
export const diffFee = async({diff, staff_type, sf, ubf, ebf}) => {
  const {standard_fee, urgentBookingFee, emergencyBookingFee} = await bookingFee(staff_type, {standard_fee: sf, urgent_booking_fee: ubf, emergency_booking_fee: ebf});
  let dateDiff = diff;
  if (dateDiff < 24 && dateDiff > 0) {
    return standard_fee + urgentBookingFee + emergencyBookingFee;
  } else if (dateDiff >= 24 && dateDiff < 48) {
    return standard_fee + urgentBookingFee;
  } else {
    return standard_fee;
  }
};

// Calculates the difference between current date and booking date
export const diffDateCalculator = state => {
  let currentDate = moment();
  if(state.created_at){
    currentDate = moment(state.created_at)
  }
  
  let bookedDate = state["date"];
  let bookedTime = state["start_time"];

  // for handling when editing booking
  bookedDate = moment(bookedDate);

  let bookedDate_hour = parseInt(bookedTime.slice(0, 2));
  let bookedDate_min = parseInt(bookedTime.slice(3, 5));

  bookedDate.set("hours", bookedDate_hour).set("minute", bookedDate_min);

  return {diff: bookedDate.diff(currentDate, "hours"), staff_type: state.staff_type, sf: state.standard_fee, ubf: state.urgent_booking_fee, ebf: state.emergency_booking_fee};
};
