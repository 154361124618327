// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";
import moment from "moment";

// Components
import StatusBar from "../ui/StatusBar";
import ShiftsOverview from "./shiftsOverview/ShiftsOverview";
import PageContainer from "../ui/PageContainer";

// Utilities
import userTypes from "../../config/userTypes";

// Styles
import colors from "../../styles/colors";
import { breakPoint } from "../../styles/breakPoints";
import color_V1 from "../../styles/colors-V-1.0";

// Utilities
import req from "../../utilities/request-utility";
import { isBookingOpen } from "../../utilities/check-booking-open";

const EmployeeHome = () => {
  const [checkoutNotifications, setCheckoutNotifications] = useState(null);
  const [checkInShiftNotification, setCheckInShiftNotification] = useState(null)

  const user = useSelector((state) => state.auth.user);
  const [meta, setMeta] = useState({});


  const getCheckoutNotifications = () => {
    req()(`notifications/employee/checkout`)
      .then(({ data: shifts }) => {
        setCheckoutNotifications(shifts);
      })
      .catch((err) => {});
  };

  //mine notification
  const mineNotification = () => {
    req().get("shifts/employee/upcoming-shifts")
    .then(({ data: shifts }) => {
      const upcomingShiftNotification = shifts?.some((shift) => {
        const openBooking = isBookingOpen(shift.booking?.date,shift.booking?.start_time)

        const currentDate = moment().toISOString();
        const bookingDate = moment(shift.booking.date);
        const twoDaysBefore = bookingDate.subtract(2, "days").startOf("day").add(6, "hours");

        if (currentDate >= twoDaysBefore.toISOString() && !shift.employee_checkin && openBooking) {
          return true
        }
      })
      setCheckInShiftNotification(upcomingShiftNotification)
    })
  }

  useEffect(() => {
    (async function getEmployeeMeta() {
      try {
        const { data: data } = await req()(`employees/${user.id}/meta`);
        setMeta(data);
      } catch (error) {
        console.log(error);
      }
    })();
    getCheckoutNotifications();
    mineNotification();
  }, []);

  return (
    <PageContainer showNavigation={true}>
      <div className={componentStyle(colors)}>
        <p className="header-title">{`Hej ${user.first_name}`}</p>
        {user.sign_in_count < 2 && (
          <div>
            <p className="sub-title">Søg freelance arbejde på få minutter.</p>
            <p className="sub-title">
              Vi har arbejde hos bl.a. Nimb, D'Angleterre, Øksnehallen, etc
            </p>
          </div>
        )}
        <div className="home-container">
          <div className="status-container">
            <StatusBar staffType={userTypes.employee} meta={meta} />
          </div>
        </div>
          <ShiftsOverview checkoutNotifications={checkoutNotifications} checkInShiftNotification={checkInShiftNotification} meta={meta} />
      </div>
    </PageContainer>
  );
};

const componentStyle = (colors) => css`
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;

  @media screen and (max-width: ${breakPoint.sm}px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .badge {
    position: absolute;
    top: -9px;
    right: -5px;
    border-radius: 50%;
    background: ${color_V1.red_20};
    color: ${color_V1.white};
    line-height: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: 600;

    &::after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }

  .header-title {
    font-size: 1.875rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 1.7rem;
    }
  }

  .sub-title {
    font-size: 1rem;
    color: ${colors.darkerGrey};
    padding-left: 1.5rem;
    margin-bottom: 0.125rem;

    &:last-child {
      margin-bottom: 1rem;
    }

    @media screen and (max-width: ${breakPoint.sm}px) {
      padding-left: 0.25rem;
    }
  }

  .home-container {
    margin-bottom: 0.75rem;

    .status-container {
      margin: 1rem 1.5rem;
    }
  }

  .navigation-container {
    ul {
      display: flex;
      border-bottom-width: 1px;
    }

    li {
      margin-bottom: -1px;
    }

    li:first-child {
      margin-right: 0.25rem;
    }

    .tab {
      width: 9.2rem;
      text-align: center;
      display: inline-block;
      border-left-width: 1px;
      border-right-width: 1px;
      border-top-width: 1px;
      border-color: ${colors.grey};
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      padding: 0.5rem 1rem;
      font-weight: 700;
      font-size: 0.75rem;
      color: ${colors.darkGrey};
      cursor: pointer;
      background-color: ${color_V1.grey_5};

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.55rem;
      }
    }

    .selected-tab {
      width: 9.2rem;
      text-align: center;
      display: inline-block;
      border-left-width: 1px;
      border-right-width: 1px;
      border-top-width: 1px;
      border-color: ${colors.grey};
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      padding: 0.5rem 1rem;
      font-weight: 700;
      font-size: 0.75rem;
      color: ${color_V1.grey_20};
      background-color: ${colors.white};
      cursor: default;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.55rem;
      }
    }
  }
`;

export default EmployeeHome;