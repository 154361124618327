// Utilities
import req from "../utilities/request-utility";
// Config
import { upcoming, finished } from "../config/bookingStatus";

// Action types
import {
  ADD_TOAST,
  END_OF_ADMIN_BOOKINGS,
  END_OF_BOOKINGS,
  GET_ADMIN_BOOKINGS,
  GET_ADMIN_BOOKINGS_FAILURE,
  GET_ADMIN_BOOKINGS_SUCCESS,
  GET_BOOKINGS,
  GET_BOOKINGS_FAILURE,
  GET_BOOKINGS_SUCCESS,
  RESET_BOOKINGS,
  GET_ADMIN_BOOKINGS_WITH_SAME_SEARCH_TERM_SUCCESS,
  GET_ADMIN_BOOKINGS_WITH_NEW_SEARCH_TERM_SUCCESS,
  CHANGE_WEEK_NUMBER,
  CHANGE_TAB,
  CHANGE_ALL_GRAVY_STATUS,
  CHANGE_FETCH_DATE,
  CHANGE_STATUS,
  CHANGE_SEND_BL_COUNT
} from "./actionTypes";
import moment from "moment";

export function getBookings({
  type = upcoming,
  limit = 10,
  offset = null,
  weekNumber,
  from='bookings'
} = {}) {
  return async function (dispatch, getState) {
    try {
      let state = getState().booking[type];
      let bookings = [];
      let totalBookings = null;

      if (offset === null) offset = state.bookings.length;

      // Safe-guard to prevent duplicate post-fetchings!
      if (state.loading || state.error || state.endOfFeed) return;

      dispatch({ type: GET_BOOKINGS, payload: { type } });

      if (type === upcoming && weekNumber) {
        const { data: openBookingsWeek } = await req()(
          `corporations/bookings/upcoming?weekNumber=${weekNumber}`
        );
        bookings = openBookingsWeek;
      }
      // Open/Active bookings
      if (type === upcoming && !weekNumber) {
        const { data: openBookings } = await req()(
          `corporations/bookings/upcoming`
        );
        bookings = openBookings;
        totalBookings = openBookings.length;
      }


      if (type === finished) {
        if(state.endOfFeedTable == 'bookings') from = 'dep_bookings';
        offset = state.bookings.filter(booking => booking.from == from).length;
        let apiUrl = `corporations/bookings/finished?limit=${limit}&offset=${offset}&from=${from}`;
        if(weekNumber){
          apiUrl = `corporations/bookings/finished-week-filter?weekNumber=${weekNumber}`
        }
        const { data: finishedBookings } = await req()(
          apiUrl
        );

        bookings = finishedBookings.data;
        if(getState().booking[type].tab == 1 && finishedBookings.tab != 1) return;
      }

      if (bookings.length || ((type === upcoming || type === finished) && weekNumber)) {
        dispatch({
          type: GET_BOOKINGS_SUCCESS,
          payload: { bookings, type, totalBookings },
        });
      } else {
        dispatch({
          type: END_OF_BOOKINGS,
          payload: { type, from },
        });
      }
    } catch (err) {
      dispatch({ type: GET_BOOKINGS_FAILURE, payload: type });
      dispatch({
        type: ADD_TOAST,
        payload: {
          message:
            "Noget gik galt, da vi prøvede at hente bookingerne. Hvis fejlen gentager sig, bedes do kontakte TjenerTeamet",
          type: "error",
          autoDismiss: true,
        },
      });
    }
  };
}

export function getAdminBookings({
  type = "admin_upcoming_bookings",
  limit = 10,
  offset = null,
  searchTerm = null,
  startDate = "",
  endDate = "",
  weekNumber = null,
  status = ''
} = {}) {
  return async function (dispatch, getState) {
    try {
      let state = getState().booking[type];
      const tab = getState().ui.filterTabUpcomingBookings
      let bookings = [];

      if (offset === null) offset = state.bookings.length;

      // Safe-guard to prevent duplicate post-fetchings!
      if (state.loading || state.error || state.endOfFeed) return;

      dispatch({ type: GET_ADMIN_BOOKINGS, payload: { type } });
      // Upcoming bookings
      if (type === "admin_upcoming_bookings" && tab != 1) {
        let reqURL = `admin/bookings/upcoming-bookings?limit=${limit}&offset=${offset}`
        if(searchTerm) reqURL += `&searchTerm=${searchTerm}`;
        if(startDate && endDate) reqURL += `&startDate=${startDate}&endDate=${endDate}`;
        if(status) reqURL += `&status=${status}`;
        const { data: upcomingBookings } = await req()(reqURL);
        bookings = upcomingBookings;
        if(weekNumber){
          dispatch({
            type: CHANGE_WEEK_NUMBER,
            payload: { weekNumber, weekNumberToDisplay: weekNumber }
          });
        }
        if (bookings.length) {
          dispatch({
            type: GET_ADMIN_BOOKINGS_SUCCESS,
            payload: { bookings, searchTerm, type }
          });
        } else {
          dispatch({
            type: END_OF_ADMIN_BOOKINGS,
            payload: { type }
          });
        }
      }

      if (type === "admin_upcoming_bookings" && tab == 1) {
        const fetchDate = state.fetchDate || startDate;
        let reqURL = `admin/bookings/upcoming-bookings-pagingbydate?fetchDate=${fetchDate}`
        if(searchTerm) reqURL += `&searchTerm=${searchTerm}`;
        const { data: upcomingBookings } = await req()(reqURL);
        bookings = upcomingBookings;
        if(weekNumber){
          dispatch({
            type: CHANGE_WEEK_NUMBER,
            payload: { weekNumber, weekNumberToDisplay: weekNumber }
          });
        }
        if (moment(fetchDate) <= moment(endDate)) {
          dispatch({
            type: GET_ADMIN_BOOKINGS_SUCCESS,
            payload: { bookings, searchTerm, type }
          });
          const newFetchDate = moment(moment(fetchDate, "YYYY-MM-DD").add(1, 'days')).format("YYYY-MM-DD");
          dispatch({
            type: CHANGE_FETCH_DATE,
            payload: { fetchDate: newFetchDate, bookingType: type }
          });
        } else {
          dispatch({
            type: END_OF_ADMIN_BOOKINGS,
            payload: { type }
          });
          dispatch({
            type: CHANGE_FETCH_DATE,
            payload: { fetchDate: null, bookingType: type }
          });
        }
      }

      // Old bookings
      if (type === "admin_old_bookings") {
        if (searchTerm === state.searchTerm && searchTerm) {
          const { data: oldBookings } = await req()(
            `admin/bookings/old-bookings?limit=${limit}&offset=${offset}&startDate=${startDate}&endDate=${endDate}&searchTerm=${searchTerm}`
          );
          bookings = oldBookings;
          if (bookings.length) {
            dispatch({
              type: GET_ADMIN_BOOKINGS_WITH_SAME_SEARCH_TERM_SUCCESS,
              payload: { bookings, searchTerm,type },
            });
          } else {
            dispatch({
              type: END_OF_ADMIN_BOOKINGS,
              payload: {type}
            });
          }
        } else if (searchTerm) {
          const { data: oldBookings } = await req()(
            `admin/bookings/old-bookings?limit=${limit}&offset=${offset}&startDate=${startDate}&endDate=${endDate}&searchTerm=${searchTerm}`
          );
          bookings = oldBookings;
          if (bookings.length) {
            dispatch({
              type: GET_ADMIN_BOOKINGS_WITH_NEW_SEARCH_TERM_SUCCESS,
              payload: { bookings, searchTerm, type },
            });
          } else {
            dispatch({
              type: END_OF_ADMIN_BOOKINGS,
              payload : {type}
            });
          }
        } else {
          const { data: oldBookings } = await req()(
            `admin/bookings/old-bookings?limit=${limit}&offset=${offset}&startDate=${startDate}&endDate=${endDate}`
          );
          bookings = oldBookings;
          
          if (bookings.length) {
            dispatch({
              type: GET_ADMIN_BOOKINGS_SUCCESS,
              payload: { bookings, type },
            });
          } else {
            dispatch({
              type: END_OF_ADMIN_BOOKINGS,
              payload: { type },
            });
          }
        }
      }
    } catch (err) {
      dispatch({ type: GET_ADMIN_BOOKINGS_FAILURE, payload: { type } });
      dispatch({
        type: ADD_TOAST,
        payload: {
          message:
            "Noget gik galt, da vi prøvede at hente bookingerne. Hvis fejlen gentager sig, bedes do kontakte TjenerTeamet",
          type: "error",
          autoDismiss: true,
        },
      });
    }
  };
}

export function resetBookings(payload) {
  return async function (dispatch, getState) {
    dispatch({ type: RESET_BOOKINGS, payload });
  };
}

export function changeWeekNumber(payload) {
  return async function (dispatch, getState) {
    dispatch({ type: CHANGE_WEEK_NUMBER, payload });
  };
}
export function changeTab(payload) {
  return async function (dispatch, getState) {
    dispatch({ type: CHANGE_TAB, payload });
  };
}
export function changeAllGravyStatus(payload) {
  return async function (dispatch, getState) {
    dispatch({ type: CHANGE_ALL_GRAVY_STATUS, payload });
  };
}
export function changeStatus(payload) {
  return async function (dispatch, getState) {
    dispatch({ type: CHANGE_STATUS, payload });
  };
}
export function changeSendBlCount(payload) {
  return async function (dispatch, getState) {
    dispatch({ type: CHANGE_SEND_BL_COUNT, payload });
  };
}