// Libs
import React, { Fragment, useState } from "react";
import ReactStars from "react-stars";
import { css } from "emotion";
import { connect, useSelector } from 'react-redux';
import { updateUser } from '../../actions/authActions';
import { bindActionCreators } from 'redux';

// Utilities and config
import { addCommas } from "../../utilities/add_commas";

// Styles
import colors from "../../styles/colors";
import { breakPoint } from '../../styles/breakPoints';

// Config
import userTypes from "../../config/userTypes";
import ImageUpload from './ImageUpload';
import req from "../../utilities/request-utility";
import Spinner from './Spinner';
import { addToast } from '../../actions/uiActions';

const baseURL_image = process.env.REACT_APP_CLOUDINARY_BASE_URL_IMAGE;

const StatusBar = (props) => {

  const user = useSelector((state) => state.auth.user);

  const [updatedUser, setUpdatedUser] = useState(user);
  const [profileSpinner, setProfileSpinner] = useState(false);


  const uploadProfileImage = file => {
    const updatedUser = {
      baseURL_image,
      profile_image: file?.imageData?.imageName
    };
    req()
      .put(`employees/`, updatedUser)
      .then(({ data: employee }) => {
        setUpdatedUser(employee);
        props.updateUser(employee);
        props.addToast({
          message: "Billedet er blevet skiftet!",
          type: "success",
          autoDismiss: true
        });
        setProfileSpinner(false);
      })
      .catch(() => {
        setProfileSpinner(false);
        props.addToast({
          message:
            "Kunne ikke gemme ændringerne. Hvis fejlen fortsætter, venligst kontakt JobButler",
          type: "warning",
          autoDismiss: true
        });
      });
  };

  return (
    <div className={componentStyle()}>
      {props.staffType === userTypes.employee &&
        (<div className="image-container">
          {!profileSpinner && (
            <ImageUpload
              spinner={setProfileSpinner}
              onFile={file => {
                uploadProfileImage(file);
              }}
              type={"corporationProfile"}
            >
              <div
                className={
                  updatedUser.profile_image
                    ? "no-image-account-container"
                    : "image-account-container"
                }
              >
                {updatedUser.profile_image && (
                  <img
                    src={`${updatedUser.baseURL_image}c_thumb,g_face,h_160,w_160/dpr_2.0,f_auto/${updatedUser.profile_image}`}
                    alt={"employee-profile"}
                  />
                )}
                {!updatedUser.profile_image && <p className='text-xs'>Profile</p>}
              </div>
            </ImageUpload>
          )}
          {profileSpinner && (
            <div className="loading-account-image-container">
              <Spinner title={"Indlæser billede"} />
            </div>
          )}
        </div>)
      }

      <div className="rating-container">
        <div>
          <p className="rating">{addCommas(props.meta.rating ? props.meta.rating : 0)}</p>
        </div>
        <div className="stars-container">
          <ReactStars
            count={5}
            edit={false}
            value={props.meta.rating ? parseInt(props.meta.rating) : 0}
            size={16}
          />
        </div>
        <Fragment>
          <div>
            <p className="rating-title">Mine ratings</p>
          </div>
        </Fragment>
      </div>

      {props.staffType === userTypes.employee && (
        <Fragment>
          <div className="jobs-container">
            <>
              <p className="jobs">{props.meta.shiftsCount ? props.meta.shiftsCount : 0}</p>
            </>
            <>
              <p className="jobs-title">Jobs taget</p>
            </>
          </div>

          <div className="jobs-container">
            <>
              <p className="jobs" style={{ color: `${colors.red}` }}>
                {props.meta.cancelledShiftsCount ? props.meta.cancelledShiftsCount : 0}
              </p>
            </>
            <>
              <p className="jobs-title">Aflysninger</p>
            </>
          </div>
        </Fragment>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  updateUser: bindActionCreators(updateUser, dispatch),
  addToast: bindActionCreators(addToast, dispatch)
});

const componentStyle = () => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 1rem;

  div.image-container {
    display: flex;
    align-items: center;

    div.loading-account-image-container {
      display: flex;
      flex-direction: column;
      height: 6rem;
      justify-content: center;
      align-items: center;
    }

    div.no-image-account-container,
    div.image-account-container {
      border: 1px solid ${colors.darkestGrey};
      border-radius: 9999px;
      height: 5rem;
      width: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      cursor: pointer;
      color: ${colors.black};

      @media screen and (max-width: ${breakPoint.sm}px) {
        height: 4rem;
        width: 4rem;
      }

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    div.image-account-container {
      text-align: center;
      font-size: 0.85rem;
    }

    div.image-account-container:hover {
      color: ${colors.white};
      background-color: ${colors.darkestGrey};
    }
  }

  .rating-container {
    display: flex;
    min-width: 7rem;
    flex-direction: column;
    justify-content: center;

    .rating {
      text-align: center;
      font-size: 1.5rem;
    }

    .rating-title {
      text-align: center;
      font-size: 13px;
      font-weight: 500;
    }

    .stars-container {
      display: flex;
      justify-content: center;
      margin-bottom: 0.25rem;
    }

    .ratings-sub-title {
      text-align: center;
      font-size: 0.75rem;
    }
  }

  .jobs-container {
    display: flex;
    flex-direction: column;
    min-width: 6rem;
    justify-content: center;

    .jobs {
      text-align: center;
      font-size: 1.5rem;
      color: ${colors.black};
    }

    .jobs-title {
      text-align: center;
      font-size: 13px;
      font-weight: 500;
    }
  }
`;

export default connect(null, mapDispatchToProps)(StatusBar);
